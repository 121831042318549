<template>
  <div>
    <v-toolbar dense flat>
      <v-app-bar-nav-icon @click="$router.push({ path: '/catalog' })">
        <v-icon>home</v-icon>
      </v-app-bar-nav-icon>

      <v-toolbar-title>Catalog</v-toolbar-title>

      <v-spacer></v-spacer>
      <v-btn
        color="teal"
        :fab="$vuetify.breakpoint.xsOnly"
        v-if="checkRightStatus(7)"
        text
        dark
        to="/catalog/create"
      >
        <span v-if="!$vuetify.breakpoint.xsOnly">New</span>
        <v-icon v-else>add</v-icon>
      </v-btn>
      <v-btn
        color="teal"
        :fab="$vuetify.breakpoint.xsOnly"
        text
        dark
        to="/catalog/list"
      >
        <span v-if="!$vuetify.breakpoint.xsOnly">List</span>
        <v-icon v-else>list</v-icon>
      </v-btn>
    </v-toolbar>
    <v-divider></v-divider>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "catalog-order",
  data() {
    return {};
  },
  created() {},
  computed: {
    ...mapGetters("global", ["currentUser", "checkRightStatus"])
  },
};
</script>
