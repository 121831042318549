<template>
  <v-container fluid grid-list-lg>
    <v-card :loading="loading" outlined>
      <ListTitle
        :showBackButton="!CustomerId"
        :access="checkRightStatus(4)"
        title="Catalog List"
        @add="add"
      />

      <v-card-title>
        <v-flex xs12 sm4>
          <v-text-field
            v-model="search"
            outlined
            prepend-inner-icon="search"
            rounded
            dense
            label="Search"
            single-line
            hide-details
            clearable
          ></v-text-field>
        </v-flex>
        <v-spacer></v-spacer>
        <v-flex v-if="!showAll" sm4 xs12>
          <DateTimePicker
            defaultRange="today"
            :range="true"
            v-model="dateTime"
            @submit="getList()"
          />
        </v-flex>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="Invoices"
          :search="search"
          :loading="loading"
          @current-items="updateTotal"
          :sort-by="sortBy"
          :page.sync="page"
          :items-per-page="itemsPerPage"
        >
          <template v-slot:item="{ item }">
            <tr class="pointer">
              <td class="text-left" @click="viewOrder(item)">
                {{ item.date | moment("MM/DD/YYYY hh:mm a") }}
              </td>
              <td class="text-left" @click="viewOrder(item)">
                {{ item.localId }}
              </td>
              <td class="text-left" v-if="!CustomerId" @click="viewOrder(item)">
                {{ item.Customer.name }}
              </td>

              <td class="text-right" @click="viewOrder(item)">
                {{ item.total | currency }}
              </td>
              <td class="text-left">
                <v-chip
                  color="green lighten-4"
                  small
                  v-if="item.completeDate"
                  text-color="green darken-4"
                  >Completed</v-chip
                >
                <v-chip
                  color="deep-orange lighten-3"
                  small
                  v-else
                  text-color="deep-orange darken-4"
                  >Pending</v-chip
                >
              </td>
              <td class="justify-end align-center text-right">
                <v-menu bottom left offset-y>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon>
                      <v-icon>more_vert</v-icon>
                    </v-btn>
                  </template>
                  <v-list class="menu-button">
                    <v-list-item v-if="!CustomerId" @click="viewProfile(item)">
                      <v-list-item-title>Profile</v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-divider v-if="!CustomerId"></v-divider>
                    <v-list-item @click="viewOrder(item)">
                      <v-list-item-title>View Order</v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item @click="editOrder(item)">
                      <v-list-item-title>Edit</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="deleteOrder(item)">
                      <v-list-item-title>Delete</v-list-item-title>
                    </v-list-item>
                    <!-- <OrderViewLink :OrderId="item.id" /> -->
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </template>
          <v-alert slot="no-results" :value="true" color="error" icon="warning"
            >Your search for "{{ search }}" found no results.</v-alert
          >
          <template v-slot:body.append="{ headers }" v-if="Invoices.length > 0">
            <tr :style="{ 'background-color': $style.listTotal.filter }">
              <td
                :colspan="headers.length - 3"
                class="text-right font-weight-bold"
              >
                List Total
              </td>

              <td class="text-right font-weight-bold">
                {{ total | currency }}
              </td>
              <td :colspan="2"></td>
            </tr>
            <tr :style="{ 'background-color': $style.listTotal.result }">
              <td
                :colspan="headers.length - 3"
                class="text-right font-weight-bold"
              >
                Result Total
              </td>

              <td class="text-right font-weight-bold">
                {{ fullTotal | currency }}
              </td>
              <td :colspan="2"></td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import orderService from "../service";
const DateTimePicker = () => import("@/components/DateTimePicker");
// import OrderViewLink from "./OrderViewLink";
import { mapGetters } from "vuex";
import Hashids from "hashids";
const hashids = new Hashids();

export default {
  props: {
    CustomerId: {
      default: null,
    },
    showAll: {
      default: false,
    },
    due: {
      default: false,
    },
  },
  name: "order-list",
  data() {
    return {
      fullTotal: 0,
      total: 0,
      dateTime: {
        start: this.$moment().startOf("day").toISOString(),
        end: this.$moment().endOf("day").toISOString(),
      },
      createDialog: false,
      count: null,
      selectedPayee: null,
      headers: [],
      Invoices: [],
      search: null,
      loading: true,
      page: 1,
      sortBy: "balance",
      pageCount: 0,
      itemsPerPage: 10,
    };
  },
  watch: {
    CustomerId: {
      immediate: true,
      handler() {
        this.getList();
      },
    },
  },
  created() {
    // this.getList();
    this.loadHeader();
    // document.title = "smplsale - Order List";
  },
  components: {
    DateTimePicker,
    // OrderViewLink,
  },
  computed: {
    ...mapGetters("global", ["currentUser", "checkRightStatus"]),
  },
  methods: {
    updateTotal(val) {
      this.total = 0;
      val.map((row) => {
        this.total += row.total;
      });
    },
    add() {
      if (this.CustomerId) {
        this.$router.push({
          path: `/order/create?CustomerId=${this.CustomerId}`,
        });
      } else {
        this.$router.push({ path: "/order/create" });
      }
    },
    viewProfile(val) {
      this.$router.push({ path: `/customer/${val.CustomerId}/profile` });
    },
    viewOrder(order) {
      const hashId = hashids.encode(order.id);
      // // console.log('hashId', hashId)
      const routeData = this.$router.resolve({
        path: `/global/order/${hashId}/view`,
      });
      window.open(routeData.href, "newwindow", "width=850, height=1100");
    },
    loadHeader() {
      this.headers = [];
      this.headers.push({
        text: this.$t("labels.date"),
        align: "left",
        value: "date",
        sortable: true,
      });

      this.headers.push({
        text: "Order Number",
        align: "left",
        value: "localId",
        sortable: true,
      });

      if (!this.CustomerId) {
        this.headers.push({
          text: "Customer Name",
          value: "Customer.name",
          align: "left",
          sortable: true,
        });
      }

      // this.headers.push({
      //   text: 'Note',
      //   align: 'left',
      //   value: 'note',
      //   sortable: true,
      // });

      this.headers.push({
        text: "Total",
        value: "total",
        align: "right",
        sortable: true,
      });

      this.headers.push({
        text: "Status",
        value: "status",
        align: "right",
        sortable: false,
      });

      // this.headers.push({
      //   text: "Payment",
      //   value: "payment",
      //   align: "right",
      //   sortable: true
      // });

      this.headers.push({
        text: "",
        value: "action",
        sortable: false,
      });
    },
    getList() {
      this.loading = true;
      let where = {};
      if (!this.showAll) {
        where = {
          start: this.dateTime.start,
          end: this.dateTime.end,
        };
      }
      if (this.CustomerId) {
        where.CustomerId = this.CustomerId;
      }

      return orderService
        .getAll(where)
        .then((response) => {
          //
          this.loading = false;
          this.Invoices = response.data;
          this.fullTotal = 0;
          this.Invoices.map((row) => {
            this.fullTotal += row.total;
          });
          this.count = response.count;
          return response;
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
    editOrder(order) {
      this.$router.push({ path: `/order/${order.id}/edit` });
    },
    deleteOrder(order) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "No",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          orderService.delete(order.id).then((response) => {
            if (response.data) {
              this.$swal("Deleted!", "Order has been deleted.", "success");
              this.getList();
            }
          });
        }
      });
    },
  },
};
</script>
