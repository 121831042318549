<template>
  <div>
    <v-toolbar color="#F0C316" dark>
      <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->

      <v-toolbar-title v-if="business">{{ business.name }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <!-- <v-btn
        color="info"
        small
        :disabled="Order.OrderDetails.length === 0"
        @click="saveOrder"
        >{{ invoiceTotal | currency }} Save</v-btn
      > -->
    </v-toolbar>

    <v-container grid-list-lg fluid v-if="OpenOrder.length !== 0">
      <v-card outlined>
        <v-card-text>
          <v-btn color="#F0C316" @click="OpenOrder = []" block>New Order</v-btn>
          <v-list subheader class="openOrderList">
            <v-subheader>List of Open Order</v-subheader>

            <v-list-item
              @click="openEditOrder(order)"
              v-for="(order, index) in OpenOrder"
              :key="index"
            >
              <!-- <v-list-item-avatar>
                <v-btn icon @click="deleteItem(index)">
                  <v-icon color="red">delete</v-icon>
                </v-btn>
              </v-list-item-avatar> -->

              <v-list-item-content>
                <v-list-item-title>{{
                  order.createdAt | moment("L LT")
                }}</v-list-item-title>
                <v-list-item-subtitle>
                  Order #{{ order.localId }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action-text>
                {{ order.total | currency }}
              </v-list-item-action-text>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-container>

    <v-container grid-list-lg fluid v-else>
      <!-- <v-alert
      v-if="selectedCustomer"
      border="left"
      type="error"
      transition="slide-y-transition"
      dismissible
      class="mb-1"
      v-model="selectedCustomer.alert"
      color="red"
    >
      <strong>{{ selectedCustomer.note }}</strong>
    </v-alert> -->
      <v-layout column v-if="!orderCreated">
        <!-- <div class="text-center mt-4" v-if="business">
          <img
            v-if="business.imageUrl"
            height="50"
            :src="business.imageUrl"
            alt="Logo"
          />

          <div class="text-h5">{{ business.name }}</div>
          <div>{{ business.phoneNumber }}</div>
        </div> -->
        <v-flex>
          <v-card outlined>
            <v-toolbar dense flat color="grey lighten-3">
              <v-toolbar-title
                >Order <span v-if="editOrder">#{{ Order.localId }}</span>
              </v-toolbar-title>
              <v-spacer> </v-spacer>
              <v-btn
                v-if="!editOrder"
                color="info"
                small
                :disabled="Order.OrderDetails.length === 0"
                @click="saveOrder"
                >{{ orderTotal | currency }} Save</v-btn
              >
              <v-btn
                v-else
                color="pink lighten-1"
                small
                dark
                :disabled="Order.OrderDetails.length === 0"
                @click="updateOrder"
                >{{ orderTotal | currency }} Update</v-btn
              >
            </v-toolbar>
            <v-card-text>
              <v-row class="pl-2" justify="space-between">
                <v-col>
                  <v-list v-if="Customer">
                    <v-list-item-title>{{ Customer.name }}</v-list-item-title>
                  </v-list>
                  <!-- <AutoComplete
                  v-model="selectedCustomer"
                  :errorMessages="selectCustomerError"
                /> -->
                </v-col>
                <!-- <v-col cols="3">
                <DateSelector label="Date" v-model="Order.date" />
              </v-col> -->
                <v-col class="shrink">
                  <v-dialog v-model="orderNote" width="500">
                    <template v-slot:activator="{ on }">
                      <v-btn outlined v-on="on">Order Note</v-btn>
                    </template>

                    <v-card>
                      <ModelTitle
                        title="Order Note"
                        @close="orderNote = !orderNote"
                      />

                      <v-card-text class="mt-3">
                        <v-textarea
                          outlined
                          dense
                          hide-details
                          v-model="Order.note"
                          label="Order Note"
                        ></v-textarea>
                      </v-card-text>

                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" text @click="orderNote = false">
                          Close
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex v-if="Customer && business">
          <ProductSearchBar
            @submit="addRow"
            :customerId="customerId"
            :remoteOrder="true"
            :business="business"
            :customer="Customer"
          />
        </v-flex>
        <v-flex>
          <v-card outlined>
            <v-card-text>
              <v-list subheader class="touch-view-container">
                <v-subheader>Order</v-subheader>

                <v-list-item
                  v-for="(order, index) in Order.OrderDetails"
                  :key="index"
                >
                  <v-list-item-avatar>
                    <v-btn icon @click="deleteItem(index)">
                      <v-icon color="red">delete</v-icon>
                    </v-btn>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title v-text="order.name"></v-list-item-title>
                    <v-list-item-subtitle
                      >{{ order.qty }} x
                      {{ order.price | currency }}</v-list-item-subtitle
                    >
                  </v-list-item-content>

                  <v-list-item-action-text>
                    {{ order.totalPrice | currency }}
                  </v-list-item-action-text>
                </v-list-item>
              </v-list>
            </v-card-text>
            <v-card v-if="orderQty > 0" outlined tile>
              <v-card-text>
                <v-row>
                  <v-col>
                    <div class="text-left">
                      <span class="font-weight-medium">{{ orderQty }}</span>
                      Total Item
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-card>
        </v-flex>
      </v-layout>

      <v-card v-else flat>
        <!-- <div class="text-center mt-4" v-if="business">
        <img
          v-if="Info.business.imageUrl"
          height="50"
          :src="Info.business.imageUrl"
          alt="Logo"
        />

        <div class="text-h5">{{ Info.business.name }}</div>
        <div>{{ Info.business.phoneNumber }}</div>
      </div> -->
        <v-card-text>
          <lottie
            :height="200"
            :options="defaultOptions"
            name="receiptCheck"
            v-on:animCreated="handleAnimation"
          />
          <div class="text-center">
            <div class="text-h5">All Set</div>
            <div class="mt-3">Your order was submitted</div>
          </div>
          <v-btn class="mt-5" color="#F0C316" @click="resetOrder" block
            >New Order</v-btn
          >
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
const { validationMixin } = require("vuelidate");
const { required, minValue } = require("vuelidate/lib/validators");
// import DateSelector from "@/components/DateSelector";
// import AutoComplete from "@/modules/Customer/components/AutoComplete";
import ProductSearchBar from "@/modules/Order/InHouse/components/ProductSearchBar";
import orderService from "@/modules/Order/RemoteOrderView/service.js";
import Hashids from "hashids";
const hashids = new Hashids();
import Lottie from "@/components/lottie.vue";
import * as animationData from "@/assets/lottie/1708-success.json";

export default {
  name: "create-remote-order",
  data() {
    return {
      editOrder: false,
      code: null,
      business: null,
      OpenOrder: [],
      orderNote: false,
      customerId: null,
      selectedCustomer: null,
      Customer: null,
      loading: true,
      Order: {
        date: this.$moment().toISOString(),
        note: null,
        CustomerId: null,
        OrderDetails: [],
      },
      defaultOptions: {
        animationData: animationData.default,
        loop: false,
        autoplay: true,
      },
      orderCreated: false,
      headers: [
        {
          text: "Name",
          align: "left",
          value: "name",
          sortable: true,
        },
        {
          text: "Quantity",
          align: "center",
          value: "qty",
          width: "100",
          sortable: true,
        },
        {
          text: "Price",
          align: "right",
          value: "price",
          sortable: true,
        },
        {
          text: "Total",
          align: "right",
          value: "total",
          sortable: true,
        },
      ],
    };
  },
  components: {
    // DateSelector,
    // AutoComplete,
    ProductSearchBar,
    Lottie,
  },
  mixins: [validationMixin],
  validations: {
    Order: {
      CustomerId: {
        required,
        minValue: minValue(1),
      },
    },
  },
  computed: {
    orderQty() {
      let total = 0;
      if (this.Order.OrderDetails) {
        this.Order.OrderDetails.map((row) => {
          total += parseFloat(row.qty);
          // total.total += parseFloat(row.total);
        });
      }

      return total;
    },
    orderTotal() {
      let total = 0;
      if (this.Order.OrderDetails) {
        this.Order.OrderDetails.map((row) => {
          // total.qty += parseFloat(row.qty);
          total += parseFloat(row.totalPrice);
        });
      }

      return total;
    },
    selectCustomerError() {
      const errors = [];
      if (!this.$v.Order.CustomerId.$dirty) return errors;
      if (!this.$v.Order.CustomerId.required)
        errors.push("Pleaes select customer");
      if (!this.$v.Order.CustomerId.minValue)
        errors.push("Pleaes select customer");
      return errors;
    },
    // invoiceTotal() {
    //   let total = 0;
    //   this.Order.OrderDetails.map((row) => {
    //     total += row.total;
    //   });
    //   return total;
    // },
  },
  watch: {
    selectedCustomer() {
      this.Order.CustomerId = this.selectedCustomer.id;
    },
  },
  async created() {
    if (this.$route.params.id) {
      const id = this.$route.params.id;
      const codeNid = id.split("-");
      this.code = hashids.decode(codeNid[0]).toString();
      this.customerId = hashids.decode(codeNid[1]).toString();
      // // console.log(";laksdjf;laksdjf", code, this.customerId);
      await orderService.getBusinessInfo(this.code).then((response) => {
        // console.log("orderService get business", response);
        this.business = response.data.business;
      });
      await orderService
        .getCustomerInfo(this.customerId, this.code)
        .then((response) => {
          // console.log("customer id customer", response);
          this.Customer = response.data;
        });

      await this.getOpenOrder();
    } else {
      // console.log("id not found");
    }
    // if (this.$route.query && this.$route.query.CustomerId) {
    //   const customer = await customerService.getById(
    //     this.$route.query.CustomerId
    //   );
    //   this.initCustomer = customer.data;
    // }
  },
  methods: {
    async getOpenOrder() {
      await orderService
        .openOrder(this.customerId, this.code)
        .then((response) => {
          // console.log("Order Order", response);
          this.OpenOrder = response.data;
        });
    },
    resetOrder() {
      this.getOpenOrder();
      this.orderCreated = false;
      this.Order = {
        date: this.$moment().toISOString(),
        note: null,
        CustomerId: null,
        OrderDetails: [],z
      };
    },
    openEditOrder(val) {
      // console.log("EditOrder", val);
      orderService.getOrderByIdPublic(val.id, this.code).then((response) => {
        // console.log("edit order", response);
        this.editOrder = true;
        this.Order = response.data;
        this.OpenOrder = [];
        // this.OpenOrder = response.data;
      });
    },
    handleAnimation(anim) {
      this.anim = anim;
    },
    saveOrder() {
      this.Order.isRemoteOrder = true;
      this.Order.CustomerId = this.Customer.id;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          })
          .fire({
            icon: "error",
            title: this.$t("toastMessage.text.invalidFields"),
          });
      } else {
        if (this.Order.OrderDetails.length > 0) {
          return orderService
            .publicCreate(this.code, this.Order)
            .then(() => {
              // console.log("result", result);
              this.$swal
                .mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                })
                .fire({
                  icon: "success",
                  title: "Order was created!",
                });

              this.orderCreated = true;
            });
        }
      }
    },
    updateOrder() {
      this.Order.isRemoteOrder = true;
      this.Order.CustomerId = this.Customer.id;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          })
          .fire({
            icon: "error",
            title: this.$t("toastMessage.text.invalidFields"),
          });
      } else {
        if (this.Order.OrderDetails.length > 0) {
          return orderService
            .publicUpdate(this.Order.id, this.code, this.Order)
            .then(() => {
              // console.log("result", result);
              this.$swal
                .mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                })
                .fire({
                  icon: "success",
                  title: "Order was updated!",
                });

              this.orderCreated = true;
            });
        }
      }
    },
    deleteItem(i) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.value) {
          this.Order.OrderDetails.splice(i, 1);
        }
      });
    },
    addRow(val) {
      // console.log("addrow", val);
      this.Order.OrderDetails.push({
        ProductId: val.id,
        name: val.Bar.name,
        price: val.Bar.price,
        totalPrice: val.Bar.price * val.Bar.qty,
        qty: val.Bar.qty,
      });
    },
  },
};
</script>

<style scoped>
.touch-view-container {
  max-height: calc(100vh - 450px);
  width: 100%;
  overflow: auto;
}

.openOrderList {
  max-height: calc(100vh - 150px);
  width: 100%;
  overflow: auto;
}
</style>
